import React from 'react';
import * as ReactGA from 'react-ga';
import Layout from '../components/Shared/Layout';
import Portfolio from '../components/Portfolio';

const $ = typeof window !== `undefined` ? require('jquery') : null;

class PortfolioPage extends React.Component {
  componentDidMount() {
    ReactGA.set({
      title: 'Portfolio.Page',
    });
    ReactGA.pageview(this.props.location.pathname);

    const filtersApplied = [];

    $('.filter').on('click', function () {
      const dataFilter = $(this).attr('data-filter');

      filtersApplied.forEach(removeClass => {
        $(`span[data-filter=${removeClass}]`).removeClass('selected');
      });

      if (filtersApplied.includes(dataFilter)) {
        $(this).removeClass('selected');
        filtersApplied.length = 0;
      } else {
        filtersApplied.length = 0;
        $(this).addClass('selected');
        filtersApplied.push($(this).attr('data-filter'));
      }
    });
  }

  render() {
    return (
      <Layout footerProps={{showPortfolioSection: false}}>
        <Portfolio />
      </Layout>
    );
  }
}

export default PortfolioPage;
