import React from 'react';
import StackGrid from 'react-stack-grid';
import Project from '../../../Shared/ProjectCard/ProjectCard';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';

function ProjectGrid({projects}) {
  const {width} = useWindowDimensions();

  return (
    <div className="container">
      <div className="portfolio-projects__grid ">
        {projects.map(project => (
          <div key={project.id}>
            <Project {...project} solutionCategories={project.categories.solutions} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProjectGrid;
