import React from 'react';

// Resizing Less Often
function debounce(fn, ms) {
  let timer;
  return _ => {
    // stop previous timer
    clearTimeout(timer);
    // start new timer
    timer = setTimeout(_ => {
      // timer id isn't needed now
      timer = null;
      // invoke the target function
      fn.apply(this, arguments);
    }, ms);
  };
}

function useWindowDimensions() {
  const [dimensions, setDimensions] = React.useState({
    height: typeof window !== 'undefined' ? window.innerHeight : null,
    width: typeof window !== 'undefined' ? window.innerWidth : null,
  });

  React.useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 1000);

    window.addEventListener('resize', debouncedHandleResize);

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });
  return dimensions;
}

export default useWindowDimensions;
