import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: '#F9F9F9',
    border: '1px solid #969696',
    borderRadius: '5px',
    minHeight: '44px',
    fontSize: '18px',
    fontFamily: 'Lato',
    fontWeight: 500,
    lineHeight: '22px',
    paddingLeft: '20px',
    color: 'rgba(77, 81, 81, 0.5)',
  }),
  menu: (provided, state) => ({
    ...provided,
    border: '1px solid #969696',
    borderTop: 'none',
    borderRadius: '0 0 5px 5px',
  }),
  option: (provided, state) => ({
    ...provided,
    background: '#F9F9F9',
    borderRadius: '5px',
    minHeight: '44px',
    fontSize: '18px',
    fontFamily: 'Lato',
    fontWeight: 500,
    lineHeight: '22px',
    paddingLeft: '20px',
    color: 'rgba(77, 81, 81, 0.5)',
    cursor: 'pointer',
    backgroundColor: state.isSelected ? '#DEE2E2' : state.isFocused ? '#F2F2F2' : null,
    ':active': {
      backgroundColor: '#D4D8D8',
    },
  }),
};

const DropdownProjectFilter = ({categories, selectedCategory, onCategoryChange}) => {
  const options = [
    {value: '', label: 'All'},
    ...categories.map(category => ({value: category, label: category})),
  ];

  const handleChange = option => {
    const category = option ? option.value : '';
    onCategoryChange(category);
  };

  const value = options.find(option => option.value === selectedCategory);

  return (
    <div className="dropdown-project-filter-container">
      <Select
        value={value}
        options={options}
        onChange={handleChange}
        placeholder="All"
        isClearable={false}
        styles={customStyles}
      />
    </div>
  );
};

DropdownProjectFilter.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedCategory: PropTypes.string,
  onCategoryChange: PropTypes.func.isRequired,
};

export default DropdownProjectFilter;
